import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import  {getAuth}  from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyAi5GI6AmLzXHCZiWaYtgPHXAZRXsXNHyg",
    authDomain: "impactsmsapp.firebaseapp.com",
    databaseURL: "https://impactsmsapp-default-rtdb.firebaseio.com",
    projectId: "impactsmsapp",
    storageBucket: "impactsmsapp.appspot.com",
    messagingSenderId: "116018607357",
    appId: "1:116018607357:web:e4e0525dfd5ec56f91ba94"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
// Initialize firebase database and get the reference of firebase database object.
const database = getDatabase(app);
export const auth  = getAuth(app);
     
export default app;