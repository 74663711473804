const baseURL = "https://uvtv-mso.impactsms.in"
const AuthID = 'b5c68648e19a28d36b6f10f8afd7a9bf724e48e5';
const domain = localStorage.getItem('userDomain');
const mobileUrl = `${baseURL}/customerlogin/mobilelogin/`;
const customerUrl = `${baseURL}/customerlogin/customerno/`;

const API = {
  // signInAPI(phone, stb) {
  //   const body = JSON.stringify({
  //     phone: phone,
  //     stb: stb,
  //     command: 'auth'
  //   });
  //   console.log('body', body);
  //   console.log('domain', mobileUrl);
  //   return fetch('https://sms.bluechipsystems.net/customerlogin/mobilelogin/', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: AuthID
  //     },
  //     body
  //   });
  // },

  getCustomerDetails(phone, stb) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'customerdetail'
    });
    console.log('body', body);
    let url = `${localStorage.getItem('userDomain')}/customerlogin/mobilelogin/`;
    console.log('domain', mobileUrl,url);
  
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },


    signInAPI(phone, stb,domain) {
    
        const body =JSON.stringify({
            phone: phone,
            stb: stb,
            "command": "auth",
            
        });
        console.log("body",body);
        console.log("domain",`${domain}/customerlogin/mobilelogin/`);
        debugger;
        return fetch(`${domain}/customerlogin/mobilelogin/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID
            },
            body
        });
    },

  getCustomerMetaData(phone, stb) {

      const body =JSON.stringify({
          phone: phone,
          stb: stb,
          command: "customersearch"

      });
      console.log("body",body);
      console.log("domain",mobileUrl);
      return fetch(mobileUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': AuthID
          },
          body
      });
  },

  getUnmappedPackages(phone, stb) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'unmapped'
    });
    console.log('body', body);
    console.log('domain', mobileUrl);
    return fetch(mobileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  getDebitsInfoList(phone, stb, page) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'customerd',
      page: page
    });
    console.log('body', body);
    console.log('domain', mobileUrl);
    return fetch(mobileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  getCreditInfoList(phone, stb, page) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'customerc',
      page: page
    });
    console.log('body', body);
    // console.log('domain', mobileUrl);
    return fetch(mobileUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  extendAPI(phone, stb, extend) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'extend',
      extend: extend
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },
  payAPI(phone, stb) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'pay'
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  disconnectAPI(phone, stb) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'disconnect'
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },
  getTransactionInfoList(phone, stb, page) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'transactions',
      page: page
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  addPackageAPI(phone, stb, packageid) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'addpackage',
      packageid: packageid
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  },

  removePackageAPI(phone, stb, packageid) {
    const body = JSON.stringify({
      phone: phone,
      stb: stb,
      command: 'removepackage',
      packageid: packageid
    });
    console.log('body', body);
    console.log('domain', customerUrl);
    return fetch(customerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthID
      },
      body
    });
  }
};

export default API;
