import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LogInScreen from '../Screens/LogIn';

import BottomNav from '../Screens/BottomNav';
export default class Navigation extends React.Component {
  isLoggedInAlready() {
    const isUSer = localStorage.getItem('UserSTBNo');
    console.log('userName', isUSer);
    // debugger;
    // debugger;
    if (isUSer) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    // localStorage.removeItem("UserSTBNo");
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/'
            render={() =>
              this.isLoggedInAlready() ? (
                <Redirect to='/home' />
              ) : (
                <LogInScreen />
              )
            }
          />
          <Route path='/home' component={BottomNav} />
          <Route path='/login' component={LogInScreen} />
        </Switch>
      </BrowserRouter>
    );
  }
}
