import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
import {
    MdVpnKey,
    MdVisibility,
    MdVisibilityOff


} from 'react-icons/md';
export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extendValue: 30
        }
    }
    getAmountwithTax(price) {
        var tax = parseInt(this.props.userMetaData.taxrate)
        var taxAmount = (parseFloat(price) * (tax / 100))
        var amount = (parseFloat(price) + taxAmount)
        return amount;

    }

    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.totalCount) {
            return (
                <Button
                    style={{ backgroundColor: "#28a745", borderColor: "#28a745", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindProcessDialog();

                    }}
                >{"OK"}</Button>
            );
        } else {
            return null;
        }
    }
    checkForLowBalance(price) {
        var req = parseInt(price);
        var bal = parseInt(this.props.Balance);
        if (req < bal) {
            return true
        } else {
            return false
        }
    }
    isShowLowBalance(price) {
        var req = parseInt(price);
        var bal = parseInt(this.props.Balance);
        if (req > bal) {
            return (
                <div>
                    <label style={{ fontWeight: "bold", color: "#EA2027" }}>Low Balance !!</label>
                </div>
            );
        } else {
            return null;
        }
    }
    showPackageList() {
        var data = [];
        data = this.props.selectedSTB.pack;
        if (data) {
            return (
                <div style={{ overflow: "auto", height: "45vh", width: "60vw" }}>
                    {data.map((item) => {
                        return (
                            <div>
                                <h6 className=" my-2 " style={{ color: "#8e44ad", fontWeight: "bold" }}>{item.name}</h6>
                                <div style={{ marginLeft: 15, marginTop: 5 }}>
                                    {item.channels.map((val) => {
                                        return (
                                            <span className="ml-2  text-primary" >{val} , </span>
                                        )
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        var price = ((this.props.userMetaData.baseprice / 30) * this.state.extendValue).toFixed(2)
        var val = price * 0.18;
        price = parseInt(val) + parseInt(price);
        return (
            <div>



                <Drawer

                    open={this.props.isShowExtendDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            {this.isShowLowBalance(price)}
                            <div style={{ color: "#2c3e50", fontSize: 24, fontWeight: "bold", textAlign: "center" }}> Extends</div>
                            <div style={{ fontSize: 18, marginTop: 10, textAlign: "center" }}>How many day<span style={{ color: "#ED4C67", fontWeight: "bold" }}>    Extends </span> ?</div>

                            <div style={{ textAlign: "end", margin: "15px 20px 10px 0px" }}>Amount : <span style={{ fontWeight: "bold", color: "#c71585 " }}>{'\u20B9'} {price}</span></div>

                            <input type="number" value={this.state.extendValue}
                                onChange={(e) => { this.setState({ extendValue: e.target.value }) }}
                                style={{ width: "40vh", marginTop: 10, border: "2px solid gray", padding: 5, }}
                            />
                            <div style={{ marginTop: 15, textAlign: "end" }}>
                                <Button
                                    style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindExtendDialog();
                                        this.setState({ extendValue: 30 })

                                    }}
                                >cancel</Button>
                                <Button
                                    style={{ backgroundColor: "#28a745", borderColor: "#28a745", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.setState({ isShowExtendWaringDialog: true });

                                    }}
                                >OK</Button>
                            </div>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer
                    open={this.state.isShowExtendWaringDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Extends</div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="monitor" />

                            <Button
                                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowExtendWaringDialog: false })
                                }}
                            >
                                cancel
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowExtendWaringDialog: false });

                                    if (this.checkForLowBalance(price)) {
                                        this.props.hindExtendDialog();
                                        this.props.extend(this.state.extendValue);
                                    } else {
                                        this.props.showLowBalanceDialog();

                                    }
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>




                <Drawer
                    open={this.props.isShowLowBalanceDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027", textAlign: "center" }}>Low Balance !</div>
                            <Undraw
                                primaryColor="#EA2027"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="c#EA2027it-card-payments" />


                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindLowBalanceDialog();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer
                    open={this.props.isShowSuccessDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#28a745" }}>Successfully Processed</div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="completed" />


                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindSuccessDialog();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>



                <Drawer
                    open={this.props.isShowErrorDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Not Processed</div>
                            <Undraw
                                primaryColor="#EA2027"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="completed" />


                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindErrorDialog();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer
                    open={this.props.isShowDisconnectDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Disconnect</div>
                            <div>Do you want to <label style={{ color: "#EA2027", fontWeight: "bold" }}>Disconnect</label> ?</div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="monitor" />

                            <Button
                                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindDisconnectDialog()
                                }}
                            >
                                cancel
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindDisconnectDialog();
                                    this.props.disconnectFunc();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer
                    open={this.props.isShowLowBalanceDialogForActivate}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027", textAlign: "center" }}>Low Balance !</div>
                            <div style={{ marginTop: 10 }}>Require Amount : <label>{price}</label></div>
                            <div style={{ marginTop: 10 }}>Your Balance : <label>{this.props.Balance}</label></div>

                            <Undraw
                                primaryColor="#EA2027"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="c#EA2027it-card-payments" />


                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindLowBalanceDialogForActivate();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer
                    open={this.props.isShowActivateDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Activate</div>
                            <div>Do you want to <label style={{ color: "#EA2027", fontWeight: "bold" }}>Activate</label> ?</div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="confirmation" />

                            <Button
                                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindActivateDialog()
                                }}
                            >
                                cancel
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindActivateDialog();
                                    this.props.ActivateAndPayNowFunc();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>



                <Drawer
                    open={this.props.isShowPayNowDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Pay now</div>
                            <div>Do you want to <label style={{ color: "#EA2027", fontWeight: "bold" }}>Pay Now </label> ?</div>
                            <div>Requre Amount :<label style={{ color: "#EA2027", fontWeight: "bold" }}>{'\u20B9' + this.getAmountwithTax(this.props.userMetaData.topay)} </label> (Inc Tax) </div>
                            <div>current Balance :<label style={{ color: "#EA2027", fontWeight: "bold" }}>{'\u20B9' + this.props.Balance} </label> </div>
                            <div>Remaing Balance:<label style={{ color: "#EA2027", fontWeight: "bold" }}>{'\u20B9' + (this.props.Balance - this.getAmountwithTax(this.props.userMetaData.topay))} </label> </div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="c#EA2027it-card-payments" />

                            <Button
                                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayNowDialog()
                                }}
                            >
                                cancel
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayNowDialog();
                                    this.props.ActivateAndPayNowFunc();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                {/* <Drawer
                    open={this.props.isShowPayNowDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#EA2027" }}>Pay now</div>
                            <div>Do you want to <label style={{ color: "#EA2027", fontWeight: "bold" }}>Pay Now </label> ?</div>
                            <Undraw
                                primaryColor="#28a745"
                                style={{ width: 300, height: 200, display: 'block', marginTop: 15, marginBottom: 15, }}
                                className="undrawimg" name="c#EA2027it-card-payments" />

                            <Button
                                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayNowDialog()
                                }}
                            >
                                cancel
              </Button>
                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayNowDialog();
                                    this.props.ActivateAndPayNowFunc();
                                }}
                            >
                                OK
              </Button>


                        </Card.Body>
                    </Card>
                </Drawer> */}

                <Drawer

                    open={this.props.isShowProcessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">

                            <div style={{ fontSize: 18, marginLeft: 20, color: "#EA2027", fontWeight: "bold", textAlign: "center" }}>
                                {this.props.noOfcompletedData}
                                <span style={{ fontSize: 18, color: "black" }}> of </span>
                                {this.props.totalCount}
                                <span style={{ fontSize: 18, color: "black" }}> is Completed Successfully </span>
                            </div>
                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                name={this.props.noOfcompletedData == this.props.totalCount ? "new-message" : "design-process"} />

                            {this.showOkBtnInProcessing()}





                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer
                    open={this.props.isShowPackagesListDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 24, fontWeight: "bold", color: "#2c3e50", textAlign: "center" }}>Channel List </div>

                            {this.showPackageList()}




                            <Button
                                style={{
                                    backgroundColor: "#28a745",
                                    borderColor: "#28a745",
                                    marginRight: 15
                                }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPackagesListDialog();
                                }}
                            >
                                OK
                            </Button>


                        </Card.Body>
                    </Card>
                </Drawer>


                <Drawer

                    open={this.props.isShowPasswordDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <center>

                                <div style={{ fontSize: 18, fontWeight: "bold" }}>Change Password</div>
                                <MdVpnKey size={50} color='#808080' style={{ marginTop: 5 }} />
                            </center>

                            <div style={{ width: 350, justifyContent: "center" }}>
                                <input
                                    onChange={e => {

                                        this.props.changeOldPassword(e.target.value)
                                    }}
                                    className="form-control"
                                    id="DomainName"
                                    // type="name"
                                    placeholder="old password"
                                    value={this.props.oldPassword}
                                    maxLength={16}
                                    minLength={8}
                                    type={this.props.showOldPassword ? "text" : "password"}
                                    style={{ width: 300, alignItems: "left", textAlign: "center", marginTop: 1, float: "left" }}
                                />

                                {this.props.showOldPassword ? <MdVisibility
                                    onClick={() => {
                                        console.log("sdsdfsd")
                                        this.props.changeShowOldPassword(!this.props.showOldPassword)
                                    }}
                                    id="DomainName12"
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    size={20} color="red" /> : <MdVisibilityOff
                                    onClick={() => {
                                        console.log("sdsdweq222fsd",this.props.showOldPassword)
                                        this.props.changeShowOldPassword(!this.props.showOldPassword)
                                    }}
                                    id="DomainName13"
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    size={20} color='#000' />}
                            </div>
                            <div style={{ width: 350, justifyContent: "center",marginTop:15 }}>
                                <input
                                    onChange={e => {

                                        this.props.changeUserPassword(e.target.value)
                                    }}
                                    className="form-control"
                                    id="DomainName1"
                                    // type="name"
                                    placeholder="New password"
                                    value={this.props.userPassword}
                                    maxLength={16}
                                    minLength={8}
                                    type={this.props.showPassword ? "text" : "password"}
                                    style={{ width: 300, alignItems: "left", textAlign: "center", marginTop: 1, float: "left" }}
                                />

                                {this.props.showPassword ? <MdVisibility
                                    onClick={() => {
                                        this.props.updateShowPassword(!this.props.showPassword)
                                    }}
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    id="DomainName14"
                                    size={20} color="red" /> : <MdVisibilityOff
                                    onClick={() => {
                                        this.props.updateShowPassword(!this.props.showPassword)
                                    }}
                                    id="DomainName15"
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    size={20} color='#000' />}
                            </div>
                            <div style={{ width: 350, justifyContent: "center",marginTop:15 }}>
                                <input
                                    onChange={e => {

                                        this.props.changeConfirmPassword(e.target.value)
                                    }}
                                    className="form-control"
                                    id="DomainName2"
                                    // type="name"
                                    placeholder="Confirm new password"
                                    value={this.props.confirmPassword}
                                    maxLength={16}
                                    minLength={8}
                                    type={this.props.showConfirmPassword ? "text" : "password"}
                                    style={{ width: 300, alignItems: "left", textAlign: "center", marginTop: 1, float: "left" }}
                                />

                                {this.props.showConfirmPassword ? <MdVisibility
                                    onClick={() => {
                                        this.props.changeShowConfirmPassword(!this.props.showConfirmPassword)
                                    }}
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    id="DomainName16"
                                    size={20} color="red" /> : <MdVisibilityOff
                                    onClick={() => {
                                        this.props.changeShowConfirmPassword(!this.props.showConfirmPassword)
                                    }}
                                    id="DomainName18"
                                    style={{ marginTop: 12, marginLeft: 10 }}
                                    size={20} color='#000' />}
                            </div>

                            <center>


                                <br></br>


                                <label style={{ fontSize: 13, color: "red" }}>{this.props.passwordErrorMessage}</label>
                            </center>
                            <div style={{ width: 350 }}>
                                <label style={{ fontSize: 13, color: "#000", marginTop: 10 }}>New password criteria :</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Minimum : 8 and Maximum : 16</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a lower and upper case latters.</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a minimum of one number.</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a anyone of the sysmbol !@=%&*</label><br></br>
                            </div>


                            <center>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindPasswordDialog();

                                    }}
                                >Cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.submitPassword();

                                    }}
                                >Submit</Button>

                            </center>

                        </Card.Body>
                    </Card>

                </Drawer>




            </div>
        );
    }
}