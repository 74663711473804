import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LoadContent, NavDiv, NavHome, MainContainer, NavSearch, NavCustomer, NavSettings, NavBulkOperations, NavLabel, NavTransactions, NavStockOperation, NavUnpaidSTB } from './styled';
import './index.css';
import Home from '../Home';
import Others from '../Others';
import {
    MdHome,
    MdTransferWithinAStation,

} from 'react-icons/md';
import CreditsScreen from '../Others/Credits';
import DebitsScreen from '../Others/Debits';
import TransactionScreen from '../Others/Transactions';
import LcoDetailsScreen from '../Others/LcoDetails';
export default class BottomNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloggedOut: false,
            content: "home",
            homeNavColor: "#9bc5e0",
            othersNavColor: "",
        }
    }
    logOut() {

        localStorage.removeItem("UserSTBNo");
        const username = localStorage.getItem("UserSTBNo");
        debugger;
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    moveToCreditScreen() {
        this.setState({content:"credits"});
    }
    moveToDebitScreen() {
        this.setState({content:"debits"});
    }
    moveToTransactionScreen() {
        this.setState({content:"transaction"});
    }
    moveToLcoDetailsScreen() {
        this.setState({content:"lcodetails"});
    }
    backToOthersScreen(){
        this.setState({content:"others"})
    }

    showTheContent(content) {
        switch (content) {
            case "home":
                return <Home


                />;
            case "others":
                return <Others
                moveToCreditScreen={this.moveToCreditScreen.bind(this)}
                moveToTransactionScreen={this.moveToTransactionScreen.bind(this)}
                moveToDebitScreen={this.moveToDebitScreen.bind(this)}
                moveToLcoDetailsScreen={this.moveToLcoDetailsScreen.bind(this)}
                />;
            case "credits":
                return <CreditsScreen
                backToOthersScreen={this.backToOthersScreen.bind(this)}
                />;
            case "debits":
                return <DebitsScreen
                backToOthersScreen={this.backToOthersScreen.bind(this)}
                />;
            case "transaction":
                return <TransactionScreen
                backToOthersScreen={this.backToOthersScreen.bind(this)}
                />;
                case "lcodetails":
                return <LcoDetailsScreen
                backToOthersScreen={this.backToOthersScreen.bind(this)}
                />;

            default:
                return null;
        }
    }

    render() {
        return (

            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
                <div style={{ flex: 1 }}>

                    <div


                        onScroll={() => console.log()}

                        style={{ width: "100%", height: "90%", overflow: "auto" }}
                    >
                        {this.showTheContent(this.state.content)}
                    </div>
                    <div
                        style={{ width: "100%", height: "10%" }}
                    >
                        <NavDiv>

                            <NavHome

                                onClick={() => {
                                    this.setState({
                                        content: "home",
                                        homeNavColor: "#9bc5e0",
                                        othersNavColor: "#ffffff",
                                    })
                                }
                                }
                                color={this.state.homeNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdHome className="font-size" />
                                    <NavLabel>Home</NavLabel>
                                </div>
                            </NavHome>

                            <NavTransactions
                                onClick={() => {
                                    this.setState({
                                        content: "others",
                                        homeNavColor: "#ffffff",
                                        othersNavColor: "#9bc5e0",

                                    })
                                }
                                }
                                color={this.state.othersNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdTransferWithinAStation className="font-size" />
                                    <NavLabel>Others</NavLabel>
                                </div>
                            </NavTransactions>




                        </NavDiv>

                    </div>

                </div>



            </div>
        );
    }
}