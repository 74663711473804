import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Col,
  Row,
  Card
} from "react-bootstrap";

import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "../styled";
import "../index.css";
import { Redirect } from "react-router-dom";
import {
  MdPermIdentity,
  MdHome,
  MdFiberPin,
  MdCreditCard,
  MdRefresh,
  MdAddCircle,
  MdRemoveCircle,
  MdPowerSettingsNew,
  MdArrowBack
} from "react-icons/md";
import loadinggif from "../../../Assets/loding-gif.gif";
import API from "../../../APIs";
import logo from "../../../Assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import HeaderComponent from "../../../GeneralComponents/Header";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    // console.log("data from search", this.props.specifyUserDetails);
    // const data = this.props.specifyUserDetails;
    // console.log("data in customer details", data);

    this.state = {
      //   userMetaData: data,
      //   userSTBDetails: {},

      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,

      spinnerVisible: false,
      pageCount: 3,
      totalPage: 0,
      count: "",
      domain: localStorage.getItem("userDomain"),
      userPhoneNo: localStorage.getItem("UserPhoneNo"),
      userSTBNo: localStorage.getItem("UserSTBNo"),
      pageNo: 3,
      tableDataValue: "",
      isVisibleConformADD_Dialog: false,
      isVisibleConformRemove_Dialog: false,
      isVisibleTransactions_Dialog: false,
      isVisibleRemove_Dialog: false,
      valuePack: 0,
      removeValuePack: 0,
      isShowSuccessDialog: false,
      creditRow: [],
      totalCount: 1,
      isShowLogOutDialog: false
    };

    this.getdata1();
    //  this.getdata1()
    console.log("try", this.props.tableData);
    window.addEventListener("scroll", this.handleScroll);
  }

  nextPageData(data, pageNo) {
    let lengthOfACustomerList = this.state.creditRow.length;
    // console.log("lenght of the list", this.state.totalCount);
    if (lengthOfACustomerList < this.state.totalCount) {
      // console.log("pageCount", pageNo);
      // this.setState({ animating: true });
      //   const { lcoID, lcoPassword, domainName } = this.state;
      //   API.LCOCreditsAPI(lcoID, lcoPassword, "lcoc", pageNo, domainName)
      const { userPhoneNo, userSTBNo } = this.state;
      API.getCreditInfoList(userPhoneNo, userSTBNo, pageNo)

        .then(response => response.json())
        .then(responsejson => {
          if (responsejson.count) {
            console.log("SearchResponse", responsejson);
            let oldData = data;
            let newData = oldData.concat(responsejson.content);
            let count = this.state.pageCount + 1;

            this.setState({
              animating: false,
              isShowSearchList: true,
              creditRow: newData,
              pageCount: count
            });
            // console.log("data", this.state.customerList);
            // if (newData.length < responsejson.count) {

            //     this.nextPageData(this.state.customerList);
            // }
          }
        })
        .catch(err => {
          console.log("error", err);
          this.setState({ isSpinnerVisible: false });
          // break
        });
    }
  }
  getdataPage3(data) {
    const { userPhoneNo, userSTBNo } = this.state;
    API.getCreditInfoList(userPhoneNo, userSTBNo, 3)
      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        // if (newData.length < responsejson.count) {

        // this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
        this.setState({
          isSpinnerVisible: false,
          isShowSearchList: true,
          creditRow: newData
        });
        // } else {
        //     this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
        // }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdataPage2(data) {
    const { userPhoneNo, userSTBNo } = this.state;
    API.getCreditInfoList(userPhoneNo, userSTBNo, 2)

      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        if (newData.length < responsejson.count) {
          this.getdataPage3(newData);
        } else {
          this.setState({
            isSpinnerVisible: false,
            isShowSearchList: true,
            creditRow: newData
          });
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdata1() {
    this.setState({ isSpinnerVisible: true });
    let data = [];
    // if(this.state.initialPageCount <=this.state.totalPageCount){

    const { userPhoneNo, userSTBNo } = this.state;
    API.getCreditInfoList(userPhoneNo, userSTBNo, 1)

      .then(response => response.json())
      .then(responsejson => {
        console.log("SearchResponse", responsejson);
        if (responsejson.message != "no data") {
          let page1Data = [];
          page1Data = responsejson.content;
          console.log("count", page1Data.length);
          this.setState({ totalCount: responsejson.count });
          if (page1Data.length < responsejson.count) {
            this.getdataPage2(page1Data);
          } else {
            this.setState({
              isSpinnerVisible: false,
              isShowSearchList: true,
              creditRow: responsejson.content
            });
          }

          //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });
        } else {
          this.setState({ isSpinnerVisible: false, isShowSearchList: false });
          // break
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  handleScroll(e) {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    // console.log("bottom",bottom);
    if (bottom) {
      this.nextPageData(this.state.creditRow, this.state.pageCount + 1);
    }
  }

  //   getLCO_Details() {
  //     debugger;
  //     this.setState({ isSpinnerVisible: true });
  //     const { lcoID, lcoPassword, domainName } = this.state;
  //     API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
  //       .then(response => response.json())
  //       .then(responsejson => {
  //         this.setState({
  //           lcoName: responsejson.lconame,
  //           lcoBalance: responsejson.balance,
  //           lcocode: responsejson.lcocode
  //         });
  //         this.getdata1();
  //       });
  //   }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }

  render() {
    return (
      <Rootcontainer onScroll={e => this.handleScroll(e)}>
        <HeaderComponent
          title={"Credits"}
          backToOthersScreen={this.props.backToOthersScreen.bind(this)}
          isShowBackArrow={true}
        />
        <LoadingOverlay
          className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.isSpinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>

        <MainContainer>
          <table className="table table-striped">
            <thead>
              <th>Created</th>
              <th>Receipt</th>
              <th>User</th>
              <th>Amount</th>
              <th>Refunded</th>
              <th>Comment</th>
            </thead>
            <tbody className="total-records">
              {this.state.creditRow.map((data, i) => {
                return (
                  <tr>
                    <td>{data.created}</td>
                    <td>{data.reciept}</td>
                    <td>{data.user}</td>
                    <td>{data.amount}</td>
                    <td>{data.refunded ? "True" : "False"}</td>
                    <td>{data.comment}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </MainContainer>

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
