import React from "react";
import {
    Button,
    ButtonGroup,
    Container,
    Col,
    Row,
    Card
} from "react-bootstrap";

import {
    Rootcontainer,
    SearchDiv,
    MainContainer,
    RootDiv,
    RootTitle,
    RootBal,
    RootRight,
    Amt,
    MoreDetails,
    RootLeft,
    RootValue,
    CustomerStatus,
    BtnGroupRight,
    STBGroup,
    STBDetails,
    CustomerStatusDiv,
    ActivPackage,
    ChannelBox,
    ChannelName,
    ChannelPrice,
    AddPackageHeading
} from "../styled";
import "../index.css";
import { Redirect } from "react-router-dom";
import {
   
    MdPersonPin
} from "react-icons/md";
import loadinggif from "../../../Assets/loding-gif.gif";
import API from "../../../APIs";
import logo from "../../../Assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import HeaderComponent from '../../../GeneralComponents/Header';
export default class index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        domain: localStorage.getItem("userDomain"),
        userPhoneNo: localStorage.getItem("UserPhoneNo"),
        userSTBNo: localStorage.getItem("UserSTBNo"),
      lcoaddress: "",
      lcocity: "",
      lcocode: "",
      lcocountry: "",
      lcodistrict: "",
      lcoemail: "",
      lcomandal: "",
      lcomobile: "",
      lcoName: "",
      lcopincode: "",
      lcoBalance: "",
      isShowChangePasswordDialog: false,
      newPassword: "",
      isShowSuccessPasswordChangedDialog: false,
      isShowErrorPasswordChangedDialog: false
    };

    this.getLCO_Details();
    //  this.getdata1()
  }

  hindChangePasswordDialog() {
    this.setState({ isShowChangePasswordDialog: false });
  }
  hindErrorPasswordChangedDialog() {
    this.setState({ isShowErrorPasswordChangedDialog: false });
  }

  getLCO_Details() {
    debugger;
    this.setState({ isSpinnerVisible: true });
    const { userPhoneNo,userSTBNo } = this.state;
    API.getCustomerDetails(userPhoneNo,userSTBNo )
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          isSpinnerVisible: false,
          lcoaddress: responsejson.lcoaddress,
          lcocity: responsejson.lcocity,
          lcomobile: responsejson.lcomobile,
          lcocode: responsejson.lcocode,
          lcocountry: responsejson.lcocountry,
          lcodistrict: responsejson.lcodistrict,
          lcoemail: responsejson.lcoemail,
          lcomandal: responsejson.lcomandal,
          lcopincode: responsejson.lcopincode
        });
        console.log("rsponse", responsejson);
      });
  }
  
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }

 
 
  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay
          className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.isSpinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>
        <HeaderComponent
        title={"Lco Details"}
        backToOthersScreen={this.props.backToOthersScreen.bind(this)}
            isShowBackArrow={true}
        />

        <MainContainer style={{ background: "#f1f1f1", height: "100vh" }}>
         
          <Container className="profile">
            <div className="circle">
              <MdPersonPin />
            </div>
            <Row>
              <Col
                md={12}
                sm={12}
                style={{ color: "white" }}
                className="text-center my-3"
              >
                <h4>Lco Details </h4>
              </Col>
              <Col md={4} sm={12}>
                <label>Name: </label>
                {this.state.lcoName}
              </Col>

              {/* <Col md={4} sm={12}>
                <label>Balance : </label>
                {"\u20B9"} {this.state.lcoBalance}
              </Col> */}
              <Col md={4} sm={12}>
                <label>Mobile Number : </label>
                {this.state.lcomobile}
              </Col>
              <Col md={4} sm={12}>
                <label>Email : </label>
                {this.state.lcoemail}
              </Col>
              <Col md={4} sm={12}>
                <label>Mandal : </label>
                {this.state.lcomandal}
              </Col>
              <Col md={4} sm={12}>
                <label>City : </label>
                {this.state.lcocity}
              </Col>
              <Col md={4} sm={12}>
                <label>District : </label>
                {this.state.lcodistrict}
              </Col>
              <Col md={4} sm={12}>
                <label>Country: </label>
                {this.state.lcocountry}
              </Col>
              <Col md={4} sm={12}>
                <label>Pin code : </label>
                {this.state.lcopincode}
              </Col>
              <Col md={4} sm={12}>
                <label>Address : </label>
                {this.state.lcoaddress}
              </Col>
              <Col md={4} sm={12}>
                <label>Lcocode : </label>
                {this.state.lcocode}
              </Col>
             
            </Row>
          </Container>
        </MainContainer>

       
      </Rootcontainer>
    );
  }
}
