import React from "react";
import "./HeaderCSS.css";
import { MdPowerSettingsNew, MdArrowBack } from "react-icons/md";
import { Redirect } from "react-router-dom";
import DialogBoxes from "./DialogBoxes";
import { Navbar } from "react-bootstrap";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLogOutDialog: false
    };
  }
  hindLogOutDialog() {
    this.setState({ isShowLogOutDialog: false });
  }

  logOut() {
    localStorage.removeItem("UserSTBNo");
    const username = localStorage.getItem("UserSTBNo");
    debugger;
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }

  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  isShowBackArrow() {
    if (this.props.isShowBackArrow) {
      return (
        <MdArrowBack
          style={{ marginTop: -8 }}
          onClick={() => this.props.backToOthersScreen()}
          className="back-button"
        />
      );
    } else {
      return null;
    }
  }
  render() {
    return (
      <React.Fragment>
        {/* <a href="#default" class="logo">{this.props.title}</a> */}
        {/* <div class="header">
          {this.isShowBackArrow()}
          <label style={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
            {this.props.title}
          </label>
          <div class="header-right">
            <MdPowerSettingsNew
              onClick={() => {
                this.setState({ isShowLogOutDialog: true });
              }}
              value="Logout"
              className="fontSize login"
            />
          </div>
          <DialogBoxes
            isShowLogOutDialog={this.state.isShowLogOutDialog}
            hindLogOutDialog={this.hindLogOutDialog.bind(this)}
            logOut={this.logOut.bind(this)}
          />
          {this.logOutFunc()}
        </div>
      */}
        <div className="header">
          <Navbar>
            {this.isShowBackArrow()}
            <Navbar.Brand href="#" className="text-white ">
              {this.props.title}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <MdPowerSettingsNew
                  onClick={() => {
                    this.setState({ isShowLogOutDialog: true });
                  }}
                  value="Logout"
                  className="fontSize login"
                />
              </Navbar.Text>
              <DialogBoxes
                isShowLogOutDialog={this.state.isShowLogOutDialog}
                hindLogOutDialog={this.hindLogOutDialog.bind(this)}
                logOut={this.logOut.bind(this)}
              />
              {this.logOutFunc()}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}
