import React from "react";
import HeaderComponent from "../../GeneralComponents/Header";

// import {Button,ButtonGroup,Container,Card} from 'react-bootstrap';
import {
  Card,
  ButtonGroup,
  Button,
  Col,
  Row,
  Container
} from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "./styled";
// import API from "../../Api";

import { Redirect } from "react-router-dom";
import logo from "../../Assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../Assets/loding-gif.gif";
// import "../../screens/Transactions/index.css";
import "./index.css";
import Drawer from "react-drag-drawer";
import {
  MdPowerSettingsNew,
  MdCreditCard,
  MdAddCircle,
  MdRemoveCircle,
  MdNotifications,
  MdInsertDriveFile,
  MdPersonPin,
  MdTrendingUp
} from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { FaCcMastercard, FaUserPlus, FaCloudDownloadAlt } from "react-icons/fa";
import Undraw from "react-undraw";
import DialogBoxes from "./DialogBoxes";
import API from "../../APIs";
export default class Others extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paygateway: "",
      isShowRechargeWarningDialog: false,
      spinnerVisible: false,
      domain: localStorage.getItem("userDomain"),
      userPhoneNo: localStorage.getItem("UserPhoneNo"),
      userSTBNo: localStorage.getItem("UserSTBNo")
    };
  }
  hindRechargeWarningDialog() {
    this.setState({ isShowRechargeWarningDialog: false });
  }
  getPayPathWay() {
    this.setState({ spinnerVisible: false });
    const { userPhoneNo, userSTBNo } = this.state;
    API.getCustomerDetails(userPhoneNo, userSTBNo)
      .then(Response => Response.json())
      .then(responseJson => {
        console.log("Lco details", responseJson);
        if (responseJson.paygateway) {
          this.setState({
            paygateway: responseJson.paygateway,
            isShowRechargeWarningDialog: true
          });
        }
        debugger;
        this.setState({ spinnerVisible: false });
      });
  }
  render() {
    return (
      <div>
        <HeaderComponent title={"Others"} />

        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              alt="logo"
              src={loadinggif}
              className="spinnerContent"
              color="green"
            />
          }
        ></LoadingOverlay>

        <Container className="mt-4">
          <Row className="transcation">
            <Col
              md={3}
              sm={6}
              style={{ margin: "10px 0px 50px", cursor: "pointer" }}
              onClick={() => {
                this.props.moveToCreditScreen();
              }}
            >
              <Card
                className="transaction-card"
                style={{
                  background:
                    "linear-gradient(to right, rgb(255, 78, 80), rgb(249, 212, 35))"
                }}
              >
                <div className="circle">
                  <MdCreditCard
                    style={{
                      background:
                        "linear-gradient(to right, rgb(253, 116, 67), rgb(251, 170, 49))"
                    }}
                  />
                </div>

                <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Credit
                </Card.Text>
              </Card>
            </Col>
            <Col
              md={3}
              sm={6}
              style={{ margin: "10px 0px 50px", cursor: "pointer" }}
              onClick={() => {
                this.props.moveToDebitScreen();
              }}
            >
              <Card
                className="transaction-card"
                style={{
                  background: "linear-gradient(to right, #4b6cb7, #182848)"
                }}
              >
                <div className="circle">
                  <FaCcMastercard
                    style={{
                      background:
                        "linear-gradient(to right, rgb(60, 88, 150), rgb(37, 57, 100))"
                    }}
                  />
                </div>

                <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Debit
                </Card.Text>
              </Card>
            </Col>

            <Col
              md={3}
              sm={6}
              style={{ margin: "10px 0px 50px", cursor: "pointer" }}
              onClick={() => {
                this.props.moveToLcoDetailsScreen();
              }}
            >
              <Card
                className="transaction-card"
                style={{
                  background:
                    "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                }}
              >
                <div className="circle">
                  <MdPersonPin
                    style={{
                      background:
                        "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                    }}
                  />
                </div>

                <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Lco Details
                </Card.Text>
              </Card>
            </Col>

            <Col
              md={3}
              sm={6}
              style={{ margin: "10px 0px 50px", cursor: "pointer" }}
              onClick={() => {
                this.props.moveToTransactionScreen();
              }}
            >
              <Card
                className="transaction-card"
                style={{
                  background:
                    "linear-gradient(to right, rgb(244, 107, 69), rgb(238, 168, 73))"
                }}
              >
                <div className="circle">
                  <MdNotifications
                    style={{
                      background:
                        " linear-gradient(to right, rgb(242, 125, 70), rgb(240, 150, 72))"
                    }}
                  />
                </div>

                <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Transactions
                </Card.Text>
              </Card>
            </Col>
            <Col
              md={3}
              sm={6}
              style={{
                margin: "10px 0px 50px",
                cursor: "pointer"
              }}
              onClick={() => {
                this.getPayPathWay();
              }}
            >
              <Card
                className="transaction-card"
                style={{
                  background: "linear-gradient(to right, #7f0055, #e10055)"
                }}
              >
                <div className="circle">
                  <IoIosSettings
                    style={{
                      padding: 12,
                      background:
                        "linear-gradient(to right, rgb(155, 0, 85), rgb(197, 0, 85))"
                    }}
                  />
                </div>

                <Card.Text style={{ color: "#fff", marginTop: "-22px" }}>
                  Balance Recharge
                </Card.Text>
              </Card>
            </Col>
          </Row>
        </Container>

        <div style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}>
          {/* <img scr={loadinggif} style={{width:500,height:400}} alt="data image"></img> */}
          Build 1.2.0.
          <DialogBoxes
            paygateway={this.state.paygateway}
            isShowRechargeWarningDialog={this.state.isShowRechargeWarningDialog}
            hindRechargeWarningDialog={this.hindRechargeWarningDialog.bind(
              this
            )}
            userSTBNo={this.state.userSTBNo}
          />
        </div>
      </div>
    );
  }
}
