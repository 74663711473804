import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import Undraw from "react-undraw";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";

export default class DialogBoxs extends React.Component {
  render() {
    return (
      <div>
        <Drawer
          open={this.props.isShowRechargeWarningDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 ">
              <div
                style={{
                  color: " #2c3e50 ",
                  fontSize: 24,
                  fontWeight: "bold",
                  width: 300,
                  textAlign: "center"
                }}
              >
                Recharge
              </div>
              <div style={{ marginLeft: "30px", marginTop: 10 }}>
                Do you want
                <span
                  style={{
                    fontWeight: "bold",
                    color: "rgb(237, 76, 103)",
                    margin: "0 5px"
                  }}
                >
                  Recharge your balance
                </span>
                ?
              </div>
              <Undraw
                style={{
                  marginTop: 20,
                  display: "block",
                  width: 300,
                  marginBottom: 10,
                  height: 150,
                  marginLeft: "-10px"
                }}
                name="credit-card-payments"
              />
              <Button
                style={{
                  backgroundColor: "#EA2027",
                  borderColor: "#EA2027",
                  marginLeft: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindRechargeWarningDialog();
                }}
              >
                Cancel
              </Button>
              <a
                style={{
                  marginLeft: 173,
                  marginTop: 15,
                  backgroundColor: "#28a745",
                  color: "#efefef"
                }}
                className="btn "
                role="button"
                onClick={() => {
                  this.props.hindRechargeWarningDialog();
                }}
                href={`${this.props.paygateway}?stb=${this.props.userSTBNo}`}
                target="_blank"
              >
                ok
              </a>
            </Card.Body>
          </Card>
        </Drawer>
      </div>
    );
  }
}
