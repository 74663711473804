import React, { Component } from "react";
import logo from "../../Assets/logo-3.png";
import "./login.css";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button
} from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';


import { Redirect } from "react-router-dom";
import API from "../../APIs";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../Assets/loding-gif.gif";
import BounceLoader from "react-spinners/BounceLoader";
import "../../Assets/index.css";
import Undraw from "react-undraw";
import DialogBoxes from "./DialogBoxes";
import firebase from '../../firebase';
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from '../../firebase';
const database = getDatabase();
// const auth = getAuth();
export default class index extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      userSTBNo: "",
      userPhoneNo: "",
      domain: "https://uvtv-mso.impactsms.in",
      isSubmitted: false,
      errorMessage: "",
      spinnerVisible: false,
      isShowError: false,
      isShowSuccessDialog: false,
      isShowErrorDialog: false,
      isShowPasswordDialog: false,
      userPassword: "",
      passwordErrorMessage: "",
      showPassword: false,
      confirmPassword: "",
      isShowOTPDialog: false,
      otp: "",
      timer: 15,
      isForResetOTP: false

    };
    // console.log("props",props);
    // if(props.match.params.id){
    //   // this.setState({domain:props.match.params.id})
    //   debugger;
    // }
  }
  hindSuccessDialog() {
    this.setState({ isShowSuccessDialog: false, isSubmitted: true });
  }
  hindErrorDialog() {
    this.setState({ isShowErrorDialog: false });
  }
  changeUserPassword(e) {
    this.setState({ userPassword: e });
  }
  hindPasswordDialog() {
    this.setState({
      isShowPasswordDialog: false,
      isShowPasswordDialog: false,
      isForResetOTP: false
    });

  }
  hindShowOTPDialog() {

    this.setState({ isShowOTPDialog: false });
  }
  updateShowPassword(e) {
    this.setState({ showPassword: e });

  }
  changeotp(e) {
    this.setState({ otp: e });
  }
  submitPassword() {
    // this.setState({ isShowPasswordDialog: false });
    this.checkPassword()
  }
  startTimer() {
    const interval = setInterval(() => {
      this.setState({ timer: this.state.timer - 1 });
      if (this.state.timer - 1 === 0) {
        clearInterval(interval);
        this.setState({ timer: 0 });
      }
    }, 1000);
    return () => clearInterval(interval);
  }

  reStartTimer() {

    this.setState({ timer: 15 });
    this.startTimer();

  }
  submit(e) {
    e.preventDefault();
    //  localStorage.setItem("userID",this.state.userName);
    //  console.log("user",localStorage.getItem("userID"));
    //  this.setState({isSubmitted:true});
    const { userPhoneNo, userSTBNo, domain } = this.state;
    debugger;
    // console.log("data", userName, userPassword, domain);
    if (userPhoneNo && userSTBNo && domain) {
      this.setState({ spinnerVisible: true, isShowError: false });
      API.signInAPI(userPhoneNo, userSTBNo, domain)
        .then(response => response.json())
        .then(responsejson => {
          console.log("responsejson", responsejson);
          debugger;
          if (responsejson.message) {
            debugger;
            localStorage.setItem("UserSTBNo", this.state.userSTBNo);
            localStorage.setItem("UserPhoneNo", this.state.userPhoneNo);
            localStorage.setItem("userDomain", domain);

            // this.setState({ isSubmitted: true, spinnerVisible: false });
            this.setState({ spinnerVisible: false, isShowPasswordDialog: true });
          } else {
            debugger;
            this.setState({
              errorMessage: responsejson.message,
              spinnerVisible: false,
              isShowErrorDialog: true,
              isForResetOTP: false
            });
          }
        })
        .catch(error => {
          this.setState({ isShowErrorDialog: true });
          this.setState({ spinnerVisible: false });
        });
    }
  }
  isSubmitSuccess() {
    if (this.state.isSubmitted) {
      return <Redirect to="/home" />;
    } else {
      return null;
    }
  }
  async sendOTP() {
    this.setState({ spinnerVisible: true, isShowError: false, isShowPasswordDialog: false });
    var number = `+91${this.state.userPhoneNo}`;
    console.log(number);
   if(!window.recaptchaVerifier){  
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      'size': 'invisible'
    });
    const appVerifier = window.recaptchaVerifier;
    try {
      const response = await signInWithPhoneNumber(auth, number, appVerifier)
      window.confirmationResult = response;
      console.log(response);
      toast.success("OTP sent successfully");
      this.reStartTimer()
      this.setState({ spinnerVisible: false, isShowOTPDialog: true });
      // .then(function (confirmationResult) {
      //   window.confirmationResult = confirmationResult;

      // });
    } catch (e) {
      console.log("e",e);
      this.setState({ spinnerVisible: false});
      toast("Due to some issue unable to send OTP now, Please later");
    }
  }else {
    try {
      const appVerifier = window.recaptchaVerifier;
      const response = await signInWithPhoneNumber(auth, number, appVerifier)
      window.confirmationResult = response;
      console.log(response);
      toast.success("OTP sent successfully");
      this.reStartTimer()
      this.setState({ spinnerVisible: false, isShowOTPDialog: true });
      // .then(function (confirmationResult) {
      //   window.confirmationResult = confirmationResult;

      // });
    } catch (e) {
      console.log("e",e);
      this.setState({ spinnerVisible: false});
      toast("Due to some issue unable to send OTP now, Please later");
    }
  }



  }
  verifyData() {
    console.log("verification");
    this.setState({ spinnerVisible: true });
    window.confirmationResult.confirm(this.state.otp).then((result) => {

      console.log("result", result);
      toast.success(`OTP verified Successfully`);

      this.setState({
        isShowOTPDialog: false,
        otp: "",
        isShowPasswordDialog: true,
        isForResetOTP: true,
        spinnerVisible: false,
        userPassword:""
      })


    }).catch((error) => {

      console.log("error verifyData", error)
      this.setState({
        spinnerVisible: false
      })
      toast.error(`Invalid OTP!`);
    });
  }
  onSignup() {
    console.log("in onSignup");
    // this.sendOTP();  
    const appVersion = window.recaptchaVerifier;
    auth().settings.appVerificationDisabledForTesting = true;
    signInWithPhoneNumber(auth, "+919962907052", appVersion)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        console.log("error", error)
        // ... 
      });

  }
  sendDataToFirebase() {
    let allow = false;
    let messageForUser = ""
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var special = /[!@=%&*]/g;
    if (!this.state.userPassword.match(lowerCase)) {

      messageForUser = "Password should contains lowercase letters!";
      allow = false;
    } else if (!this.state.userPassword.match(upperCase)) {

      messageForUser = "Password should contain uppercase letters!";
      allow = false;
    } else if (!this.state.userPassword.match(numbers)) {
      messageForUser = "Password should contains numbers also!";
      allow = false;
    }
    else if (!this.state.userPassword.match(special)) {
      messageForUser = "Password should contains !@=%&* any one!";
      allow = false;
    } else if (this.state.userPassword.length < 8) {
      messageForUser = "Password length should be more than or equal to 8!";
      allow = false;
    } else {
      allow = true;
    }

    if (allow) {
      set(ref(database, `users/${this.state.userPhoneNo}`), {
        phoneNo: `${this.state.userPhoneNo}`,
        stb: `${this.state.userPhoneNo}`,
        password: `${this.state.userPassword}`
      }).then(e => {
        console.log(e);
        if (this.state.isForResetOTP) {
          toast.success("Password reset successfully");
          this.setState({ spinnerVisible: false, passwordErrorMessage: "", isForResetOTP: false, isShowPasswordDialog: false });
        }else{
          this.setState({ spinnerVisible: false, passwordErrorMessage: "", isForResetOTP: false, isShowPasswordDialog: false,isSubmitted: true });
          
        }
        

      }).catch(error => {
        console.log(error);
        this.setState({ spinnerVisible: false, passwordErrorMessage: "Something went wrong!" });
      });
    } else {
      this.setState({ spinnerVisible: false, passwordErrorMessage: messageForUser });
    }
  }
  checkPassword() {
    this.setState({ spinnerVisible: true, passwordErrorMessage: "" });
    const starCountRef = ref(database, `users/${this.state.userPhoneNo}`);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("data data", data)
      if (data == null) {
        this.sendDataToFirebase();
      } else {
        console.log("password", this.state.userPassword);
        if (data.password == this.state.userPassword) {
          this.setState({ isSubmitted: true, spinnerVisible: false, passwordErrorMessage: "", isShowPasswordDialog: false, userPassword: "" });
        } else {
          this.setState({ isSubmitted: false, spinnerVisible: false, passwordErrorMessage: "Incorrect Password" });
        }
      }
    });
  }
  render() {
    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              alt="logo"
              src={loadinggif}
              className="spinnerContent"
              color="green"
            />
          }
        ></LoadingOverlay>
        <div className="recaptcha-container" id="recaptcha-container"></div>

        {/* <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100">
              <Undraw
                className="login100-pic js-tilt"
                data-tilt
                name="starman"
                style={{ width: 500, height: 500, marginTop: -100 }}
              />

              <form className="login100-form validate-form login-form">
                <img src={logo} className="logo-style" alt="logo" />
                <span className="login100-form-title">Login old</span>

                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    onChange={e => {
                      this.setState({ domain: e.target.value });
                    }}
                    className="input100"
                    name="email"
                    placeholder="* Domain"
                    value={this.state.domain}
                  />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <i className="fa fa-plug" aria-hidden="true"></i>
                  </span>
                </div>

                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    onChange={e => {
                      this.setState({ userSTBNo: e.target.value });
                    }}
                    className="input100"
                    name="email"
                    placeholder="* STB Number"
                    value={this.state.userSTBNo}
                  />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </span>
                </div>

                <div
                  className="wrap-input100 validate-input"
                  data-validate="Password is required"
                >
                  <input
                    onChange={e => {
                      this.setState({ userPhoneNo: e.target.value });
                    }}
                    className="input100"
                    type="number"
                    min={1}
                    max={10}
                    name="pass"
                    placeholder="* Phone number"
                    value={this.state.userPhoneNo}
                  />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="container-login100-form-btn">
                  <button
                    onClick={e => {
                      this.submit(e);
                    }}
                    className="login100-form-btn"
                  >
                    Login
                  </button>
                </div>

                <div style={{ marginTop: -50 }} className="text-center p-t-136">
                  <label> Powered by Impact sms</label>
                </div>
              </form>
            </div>
          </div>
        </div> */}

        {/* second form  */}
        <div id="login-form">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                  <div className="card-body">
                    <img src={logo} className="login-logo" alt="logo" />
                    <h5 className="card-title text-center">Log In </h5>

                    <form className="form-signin">
                      {/* <div className="form-label-group">
                       
                        <input
                          onChange={e => {
                            this.setState({ domain: e.target.value });
                          }}
                          className="form-control"
                          id="DomainName"
                          type="name"
                          placeholder=" Domain"
                          value={this.state.domain}
                        />
                        <label htmlFor="DomainName">Domain</label>
                      </div> */}
                      <div className="form-label-group">
                        {/* <input
                          type="name"
                          id="StbNumber"
                          className="form-control"
                          placeholder="STB Number"
                          required
                        /> */}
                        <input
                          onChange={e => {
                            this.setState({ userSTBNo: e.target.value });
                          }}
                          id="StbNumber"
                          className="form-control"
                          name="email"
                          placeholder="STB Number"
                          value={this.state.userSTBNo}
                        />
                        <label htmlFor="StbNumber">STB Number</label>
                      </div>
                      <div className="form-label-group">
                        {/* <input
                          type="name"
                          id="PhoneNumber"
                          className="form-control"
                          placeholder="Phone Number"
                          required
                        /> */}
                        <input
                          onChange={e => {
                            this.setState({ userPhoneNo: e.target.value });
                          }}
                          id="PhoneNumber"
                          type="number"
                          min={1}
                          max={10}
                          name="pass"
                          className="form-control"
                          placeholder="Phone Number"
                          value={this.state.userPhoneNo}
                        />
                        <label htmlFor="PhoneNumber">Phone Number </label>
                      </div>

                      <button
                        onClick={e => {
                          this.submit(e);
                          // this.sendDataToFirebase(e)
                        }}
                        className="btn btn-lg btn-primary btn-block text-uppercase"
                      >
                        Log In
                      </button>
                      <h6 className="mt-4 text-center">
                        Powered by Impact sms
                      </h6>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.isSubmitSuccess()}
        <DialogBoxes
          isShowSuccessDialog={this.state.isShowSuccessDialog}
          hindSuccessDialog={this.hindSuccessDialog.bind(this)}
          isShowErrorDialog={this.state.isShowErrorDialog}
          hindErrorDialog={this.hindErrorDialog.bind(this)}
          errorMessage={this.state.errorMessage}
          isShowPasswordDialog={this.state.isShowPasswordDialog}
          changeUserPassword={this.changeUserPassword.bind(this)}
          hindPasswordDialog={this.hindPasswordDialog.bind(this)}
          userPassword={this.state.userPassword}
          passwordErrorMessage={this.state.passwordErrorMessage}
          submitPassword={this.submitPassword.bind(this)}
          showPassword={this.state.showPassword}
          updateShowPassword={this.updateShowPassword.bind(this)}
          confirmPassword={this.state.confirmPassword}
          sendOTP={this.sendOTP.bind(this)}
          verifyData={this.verifyData.bind(this)}
          isShowOTPDialog={this.state.isShowOTPDialog}
          changeotp={this.changeotp.bind(this)}
          otp={this.state.otp}
          startTimer={this.startTimer.bind(this)}
          timer={this.state.timer}
          reStartTimer={this.reStartTimer.bind(this)}
          hindShowOTPDialog={this.hindShowOTPDialog.bind(this)}
          isForResetOTP={this.state.isForResetOTP}
          sendDataToFirebase={this.sendDataToFirebase.bind(this)}
        />
        <Toaster />
      </div>
    );
  }
}
