import React from 'react';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus, BtnGroupRight, STBGroup, STBDetails,
    CustomerStatusDiv, ActivPackage, ChannelBox, ChannelName, ChannelPrice, AddPackageHeading, UnmappedHeading

} from './styled';
import loadinggif from '../../Assets/loding-gif.gif';
import { Redirect } from 'react-router-dom';
import logo from '../../Assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import RemovePackageDialog from './RemovePackage';

import User from '../../Assets/icon/user.svg';
import Calendar from '../../Assets/icon/calendar.svg';
import Maps from '../../Assets/icon/maps.svg';
import STB from '../../Assets/icon/wifi.svg';
import CreditCard from '../../Assets/icon/credit-card.svg';
import Bills from "../../Assets/icon/bill.svg"

import { FormControl, FormGroup, FormLabel, Container, ControlLabel, HelpBlock, Checkbox, Radio, Button, InputGroup, Form, Badge, Row, Col } from 'react-bootstrap';
import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdCreditCard,
    MdRefresh,
    MdPowerSettingsNew,
    MdArrowBack,
    MdAdd,
    MdRemoveCircleOutline,
    MdRemoveCircle,
    MdAddCircle,
    MdAddCircleOutline,
    MdDone,
    MdError,
    MdLiveTv,
    MdModeEdit,
    MdVpnKey


} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import API from '../../APIs';

import { Card } from 'react-bootstrap';
import {
    FaSearch, FaSpinner, FaSleigh
} from 'react-icons/fa';


import './index.css';

import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import DialogBoxes from './DialogBoxes';

import Undraw from "react-undraw";
import HeaderComponent from '../../GeneralComponents/Header';
import AddPackageDialog from './AddPackage';
import { getDatabase, ref, set, onValue } from "firebase/database";
const database = getDatabase();
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            domain: localStorage.getItem("userDomain"),
            userPhoneNo: localStorage.getItem("UserPhoneNo"),
            userSTBNo: localStorage.getItem("UserSTBNo"),
            spinnerVisible: false,
            userMetaData: {},
            selectedSTB: {},
            channelsList: [],
            Balance: 0,
            isShowExtendDialog: false,
            isShowLowBalanceDialog: false,
            isShowSuccessDialog: false,
            isShowErrorDialog: false,
            isShowDisconnectDialog: false,
            isShowLowBalanceDialogForActivate: false,
            isShowActivateDialog: false,
            isShowPayNowDialog: false,
            removePackageSearchResult: [],
            isShowRemovePackageDialog: false,
            isShowAddPackageDialog: false,
            addPackageList: [],
            addPackageSearchResult: [],
            removePackageList: [],
            isProcess: false,
            totalCount: 0,
            selectedPackageID: [],
            noOfcompletedData: 0,
            isShowProcessDialog: false,
            isShowPackagesListDialog: false,
            isShowPasswordDialog: false,
            userPassword: "",
            passwordErrorMessage: "",
            showPassword: false,
            confirmPassword: "",
            oldPassword: "",
            showOldPassword: false,
            showConfirmPassword: false


        }

    }
    componentDidMount() {
        this.getLcoDetails();
    }
    hindExtendDialog() {
        this.setState({ isShowExtendDialog: false })
    }
    hindLowBalanceDialog() {
        this.setState({ isShowLowBalanceDialog: false })
    }
    showLowBalanceDialog() {
        this.setState({ isShowLowBalanceDialog: true })
    }
    hindSuccessDialog() {
        this.setState({ isShowSuccessDialog: false })
        this.getLcoDetails();
    }
    hindErrorDialog() {
        this.setState({ isShowErrorDialog: false });
    }
    hindDisconnectDialog() {
        this.setState({ isShowDisconnectDialog: false })
    }
    hindLowBalanceDialogForActivate() {
        this.setState({ isShowLowBalanceDialogForActivate: false });
    }
    hindActivateDialog() {
        this.setState({ isShowActivateDialog: false });
    }
    hindPayNowDialog() {
        this.setState({ isShowPayNowDialog: false })
    }
    hindPackagesListDialog() {
        this.setState({ isShowPackagesListDialog: false })
    }
    showRemovePackage() {
        var packages = this.state.selectedSTB.pack;
        var arr = [];
        for (var data of packages) {
            data.toggleMode = false
            arr.push(data);
        }
        console.log("arr", arr);
        debugger;
        this.setState({ removePackageSearchResult: arr, isShowRemovePackageDialog: true, removePackageList: arr });

    }
    hindRemovePackageDialog() {
        this.setState({ isShowRemovePackageDialog: false });
    }

    hindAddPackageDialog() {
        this.setState({ isShowAddPackageDialog: false })
    }
    hindProcessDialog() {
        this.setState({ isShowProcessDialog: false })
        this.getLcoDetails();
    }
    changeUserPassword(e) {
        this.setState({ userPassword: e });
    }
    changeConfirmPassword(e) {
        this.setState({ confirmPassword: e });
    }
    changeOldPassword(e) {
        this.setState({ oldPassword: e });
    }

    hindPasswordDialog() {
        this.setState({ isShowPasswordDialog: false, userPassword: "" });

    }
    updateShowPassword(e) {
        this.setState({ showPassword: e });

    }
    submitPassword() {
        // this.setState({ isShowPasswordDialog: false });
        this.checkPassword()
    }
    changeShowConfirmPassword(e) {
        this.setState({ showConfirmPassword: e });

    }
    changeShowOldPassword(e) {
        this.setState({ showOldPassword: e });

    }

    getUnmappedPackageList() {
        this.setState({ spinnerVisible: true })
        const { userPhoneNo, userSTBNo, selectedSTB } = this.state;
        API.getUnmappedPackages(userPhoneNo, selectedSTB.SNo)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("UnMap result", responseJson);
                debugger;
                if (responseJson.products) {
                    var org = responseJson.products;

                    for (var data of org) {
                        data.toggleMode = false
                    }
                    this.setState({ addPackageList: org, addPackageSearchResult: org, isShowAddPackageDialog: true })
                } else {
                    this.setState({ isShowErrorDialog: true });
                }

                this.setState({ spinnerVisible: false })

            }).catch((error) => {
                this.setState({ spinnerVisible: false })
            });

    }


    ActivateAndPayNowFunc() {
        this.setState({ spinnerVisible: true })
        const { userPhoneNo, userSTBNo } = this.state;
        API.payAPI(userPhoneNo, userSTBNo)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Activate and pay response", responseJson);
                debugger;
                if (responseJson.message == "processed") {
                    this.setState({ isShowSuccessDialog: true })
                } else {
                    this.setState({ isShowErrorDialog: true });
                }
                this.setState({ spinnerVisible: false })

            }).catch((error) => {
                this.setState({ spinnerVisible: false })
            });

    }
    disconnectFunc() {
        this.setState({ spinnerVisible: true })
        const { userPhoneNo, userSTBNo } = this.state;
        API.disconnectAPI(userPhoneNo, userSTBNo)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("disconnet response", responseJson);
                debugger;
                if (responseJson.message == "processed") {
                    this.setState({ isShowSuccessDialog: true })
                } else {
                    this.setState({ isShowErrorDialog: true });
                }
                this.setState({ spinnerVisible: false })

            }).catch((error) => {
                this.setState({ spinnerVisible: false })
            });

    }
    extend(extend) {
        this.setState({ spinnerVisible: true })
        const { userPhoneNo, userSTBNo } = this.state;
        API.extendAPI(userPhoneNo, userSTBNo, extend)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("extend response", responseJson);
                debugger;
                if (responseJson.message == "processed") {
                    this.setState({ isShowSuccessDialog: true })
                } else {
                    this.setState({ isShowErrorDialog: true });
                }
                this.setState({ spinnerVisible: false })

            }).catch((error) => {
                this.setState({ spinnerVisible: false })
            });


    }
    sendDataToFirebase(data) {
        let allow = false;
        let messageForUser = ""
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var special = /[!@=%&*]/g;
        if (!this.state.userPassword.match(lowerCase)) {

            messageForUser = "Password should contains lowercase letters!";
            allow = false;
        } else if (!this.state.userPassword.match(upperCase)) {

            messageForUser = "Password should contain uppercase letters!";
            allow = false;
        } else if (!this.state.userPassword.match(numbers)) {
            messageForUser = "Password should contains numbers also!";
            allow = false;
        }
        else if (!this.state.userPassword.match(special)) {
            messageForUser = "Password should contains !@=%&* any one!";
            allow = false;
        } else if (this.state.userPassword.length < 8) {
            messageForUser = "Password length should be more than or equal to 8!";
            allow = false;
        } else {
            allow = true;
        }


        if (allow) {
            if (this.state.confirmPassword == this.state.userPassword) {


                set(ref(database, `users/${this.state.userPhoneNo}`), {
                    phoneNo: `${data.phoneNo}`,
                    stb: `${data.stb}`,
                    password: `${this.state.userPassword}`
                }).then(e => {
                    console.log(e);
                    this.setState({
                        spinnerVisible: false,
                        passwordErrorMessage: "",
                        isShowSuccessDialog: true,
                        isShowPasswordDialog: false,
                        userPassword: "",
                        confirmPassword: "",
                        oldPassword: "",
                        showOldPassword: false,
                        showConfirmPassword: false,
                        showPassword:false
                    });
                }).catch(error => {
                    console.log(error);
                    this.setState({ spinnerVisible: false, passwordErrorMessage: "Something went wrong!" });
                });
            } else {
                this.setState({ spinnerVisible: false, passwordErrorMessage: "Confirm password is not matched!" });
            }
        } else {
            this.setState({ spinnerVisible: false, passwordErrorMessage: messageForUser });
        }
    }
    checkPassword() {
        this.setState({ spinnerVisible: true, passwordErrorMessage: "" });
        const starCountRef = ref(database, `users/${this.state.userPhoneNo}`);
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            console.log("data data", data)
            if (data.password == this.state.oldPassword) {
                this.sendDataToFirebase(data);
            } else {
                console.log("password", this.state.userPassword);
                //  if(data.password == this.state.userPassword){
                this.setState({ isSubmitted: true, spinnerVisible: false, passwordErrorMessage: "Old Password is not matched!" });
                //  }else{
                //   this.setState({ isSubmitted: false, spinnerVisible: false,passwordErrorMessage:"Incorrect Password" });
                //  }
            }
        });
    }
    getLcoDetails() {
        this.setState({ spinnerVisible: true, isProcess: false })
        const { userPhoneNo, userSTBNo } = this.state;
        API.getCustomerDetails(userPhoneNo, userSTBNo)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Get Customer", responseJson)
                debugger;
                if (responseJson.lconame) {
                    this.setState({ Balance: responseJson.balance })
                    this.getCustomerDetails();
                } else {
                    this.setState({ errorMessage: "Unable to process !", spinnerVisible: false })
                }

            }).catch(e => {
                this.setState({ errorMessage: "Unable to process !", spinnerVisible: false })
            })

    }
    getCustomerDetails() {
        const { userPhoneNo, userSTBNo } = this.state;
        API.getCustomerMetaData(userPhoneNo, userSTBNo)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Get customer details", responseJson);
                debugger;
                if (responseJson.Customernum) {
                    this.setState({ userMetaData: responseJson, selectedSTB: responseJson.stbs[0], isProcess: responseJson.process, spinnerVisible: false })
                } else {
                    this.setState({ errorMessage: "Unable to process !", spinnerVisible: false })
                }

            }).catch(e => {
                this.setState({ errorMessage: "Unable to process !", spinnerVisible: false })
            })
    }
    checkForLowBalance(data) {
        console.log(data);
        debugger;
    }
    removePackageSearch(text) {
        var packArr = this.state.removePackageList;
        var lower = text.toString().toLowerCase();
        var result = []
        for (var data of packArr) {
            var text1 = data.name;
            var lowerName = text1.toString().toLowerCase();
            var n = lowerName.search(lower)
            if (n != -1) {
                result.push(data)
            }
        }

        this.setState({ removePackageSearchResult: result });

    }
    addPackageSearchFunc(text) {
        var packArr = this.state.addPackageList;
        var lower = text.toString().toLowerCase();
        var result = []
        for (var data of packArr) {
            var text1 = data.name;
            var lowerName = text1.toString().toLowerCase();
            var n = lowerName.search(lower)
            if (n != -1) {
                result.push(data)
            }
        }

        this.setState({ addPackageSearchResult: result });
    }

    isLowBalance(price) {
        var bal = parseInt(this.state.Balance);
        var taxPer = parseInt(this.state.userMetaData.taxrate);
        var taxAmount = parseInt(bal * (taxPer / 100))
        var req = taxAmount + price;
        if (req > bal) {
            return true
        } else {
            return false
        }

    }
    removePackageRecurse(i) {


        var { totalCount } = this.state;
        debugger;
        if (i < totalCount) {
            const { userPhoneNo, userSTBNo, selectedSTB, selectedPackageID } = this.state;
            API.removePackageAPI(userPhoneNo, selectedSTB.SNo, selectedPackageID[i])
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("Remove package", responseJson);
                    this.setState({ noOfcompletedData: i + 1 })
                    debugger;
                    if (responseJson.message == "processed") {
                        if (i + 1 < totalCount) {
                            this.removePackageRecurse(i + 1);
                        }
                    } else {
                        if (i + 1 < totalCount) {
                            this.removePackageRecurse(i + 1);
                        }
                    }


                }).catch((error) => {
                    debugger;
                    console.log("error in remove", error)
                    if (i + 1 < totalCount) {
                        this.removePackageRecurse(i + 1);
                    }
                });

        } else {
            this.setState({ spinnerVisible: false });
        }


    }
    removePackageFunc() {
        var packages = this.state.removePackageSearchResult;
        var idArr = [];
        for (var data of packages) {
            if (data.toggleMode) {
                idArr.push(data.ProductCASID);
            }
        }
        debugger;

        this.setState({ isShowProcessDialog: true })
        //    this.setState({selectedPackageForRemove:idArr,totalCount:idArr.length});
        this.state.selectedPackageID = idArr;
        this.state.totalCount = idArr.length;
        this.state.noOfcompletedData = 0;
        console.log("sele", idArr, idArr.length);
        debugger;
        this.removePackageRecurse(0);

    }

    addPackageRecurse(i) {


        var { totalCount } = this.state;
        debugger;
        if (i < totalCount) {
            const { userPhoneNo, userSTBNo, selectedSTB, selectedPackageID } = this.state;
            API.addPackageAPI(userPhoneNo, selectedSTB.SNo, parseInt(selectedPackageID[i]))
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("add package", responseJson);
                    debugger;
                    this.setState({ noOfcompletedData: i + 1 })
                    if (responseJson.message == "processed") {
                        if (i + 1 < totalCount) {
                            this.addPackageRecurse(i + 1);
                        }
                    } else {
                        if (i + 1 < totalCount) {
                            this.addPackageRecurse(i + 1);
                        }
                    }


                }).catch((error) => {
                    debugger;
                    console.log("error in remove", error)
                    if (i + 1 < totalCount) {
                        this.addPackageRecurse(i + 1);
                    }
                });

        } else {
            this.setState({ spinnerVisible: false });
        }


    }

    addPackageFunc() {
        var packages = this.state.addPackageList;
        var idArr = [];
        for (var data of packages) {
            if (data.toggleMode) {
                idArr.push(data.id);
            }
        }


        this.setState({ isShowProcessDialog: true })
        this.state.selectedPackageID = idArr;
        this.state.totalCount = idArr.length;
        this.state.noOfcompletedData = 0;
        console.log("sele", idArr, idArr.length);
        debugger;
        this.addPackageRecurse(0);


    }

    toggleChangedInRemovePackages(id) {
        var packages = this.state.removePackageList;
        for (var data of packages) {
            if (data.ProductCASID == id) {
                debugger;
                data.toggleMode = !data.toggleMode

            }
        }
        this.setState({ removePackageList: packages });
    }

    toggleChangedInAddPackages(id) {
        var Packages = this.state.addPackageSearchResult;
        var allPackage = this.state.addPackageList;
        // for(var item of Packages ){
        //     if(item.id==id){
        //         item.toggleMode=!item.toggleMode;
        //     }
        // }
        for (var item1 of allPackage) {
            if (item1.id == id) {
                item1.toggleMode = !item1.toggleMode;
            }
        }
        this.setState({
            // addPackageSearchResult:Packages,
            addPackageList: allPackage
        });
    }
    renderTheSTB() {
        const currentPack = this.state.selectedSTB.pack;

        if (this.state.userMetaData.stbs)
            return (


                <div>
                    <div style={{ fontSize: 17, fontWeight: 600 }}>STB Number : <label style={{ color: "rgb(39, 0, 255)", fontWeight: 600 }}>{this.state.selectedSTB.SNo}</label></div>
                    <div className="row" style={{ padding: "0px 17px" }}>
                        {currentPack.map((data, i) => {
                            return (
                                <div key={i} className="col-xs-12 col-sm-6 col-md-3 col-lg-2 pack-box">

                                    <ChannelBox
                                        style={{ height: 140, overflow: "auto", border: "2px solid #bdc3c7" }}
                                    >
                                        <ChannelName>{data.name}</ChannelName>
                                        <ChannelPrice>{'\u20B9'} {data.baseprice}</ChannelPrice>



                                        {/* <div
                                            onMouseOverCapture={() => { this.setState({ channelsList: data.channels }) }}

                                            className="above">
                                            <MdLiveTv color="gray" />

                                            <div


                                            >
                                                <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Channels:</label>
                                                <ul

                                                    style={{ listStyle: "none", padding: 10 }}>
                                                    {this.state.channelsList.map((item, i) => {

                                                        return (
                                                            <li

                                                                style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                                                {item}</li>
                                                        );
                                                    })}
                                                </ul>

                                            </div>



                                        </div> */}


                                    </ChannelBox>
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-1">



                    </div>
                </div>
            );

    }
    isShowStarAndEndDate(data) {
        if (data.daily) {
            return (
                <div style={{ marginTop: 10 }}>
                    <label className="title">Start Date :  <span style={{ color: "red" }}> {data.start}</span></label>
                    <label className="title">    end Date :  <span style={{ color: "red" }}> {data.end}</span> </label>
                </div>
            );
        } else {
            return null;
        }
    }
    showSTBSBtn(item) {
        if (item.stbs) {
            return (
                <div>
                    {item.stbs.map((data, i) => {
                        return (
                            <Button variant={data.toggle ? "success" : "primary"} className="text-white mr-1" style={{ fontWeight: 600 }} onClick={() => {
                                this.setState({ selectedSTB: this.state.userMetaData.stbs[i] })
                                localStorage.setItem("UserSTBNo", this.state.userMetaData.stbs[i].SNo);

                            }} key={i}>STB {i + 1} </Button>
                        );
                    })}
                </div>
            );

        } else {
            return null;
        }
    }
    whichPayOrExtendBtn(data) {
        if (data.daily) {
            return (
                <Button

                    style={{ backgroundColor: "#ED4C67", borderColor: "#ED4C67" }}
                    size="sm" className="mr-1 ml-1  btn-size" onClick={() => { this.setState({ isShowExtendDialog: true }) }}>Extend</Button>
            );
        } else {
            return (
                <Button
                    style={{ backgroundColor: "green", borderColor: "green" }}
                    variant="info" size="sm" className="mr-1 ml-1 my-2 btn-size" onClick={() => {
                        if (this.isLowBalance(this.state.userMetaData.baseprice)) {
                            this.setState({ isShowPayNowDialog: true });
                        } else {
                            this.setState({ isShowLowBalanceDialogForActivate: true });
                        }
                    }}>Pay Now</Button>
            );

        }
    }

    whichActivateOrDisconnectBtn(data) {
        if (data.connectionstatus == "Live") {
            return null;
            // return (
            //     <Button

            //         style={{ backgroundColor: "red", borderColor: "red" }}
            //         size="sm" className="mr-1 ml-1 my-2 btn-size" onClick={() => { this.setState({ isShowDisconnectDialog: true }) }}>Disconnect</Button>
            // );
        } else {
            return (
                <Button
                    style={{ backgroundColor: "#802B66", borderColor: "#802B66" }}
                    variant="info" size="sm" className="mr-1 ml-1 my-2 btn-size" onClick={() => {
                        if (this.isLowBalance(this.state.userMetaData.baseprice)) {
                            this.setState({ isShowActivateDialog: true });
                        } else {
                            this.setState({ isShowLowBalanceDialogForActivate: true });
                        }
                    }}>Activate</Button>
            );

        }
    }
    render() {
        const { userMetaData } = this.state;
        console.log("user", userMetaData);
        return (
            <div >
                <HeaderComponent
                    title={"Home"}
                />



                <div>



                </div>

                <LoadingOverlay
                    className={this.state.spinnerVisible ? 'displaySpinner' : ''}
                    overlay={true}
                    active={this.state.spinnerVisible}
                    spinner={
                        <img
                            alt='logo'
                            src={loadinggif}
                            className='spinnerContent'
                            color='green'
                        />
                    }
                ></LoadingOverlay>
                {/* Main Div */}
                <div style={{ marginTop: 10, marginLeft: 15, marginRight: 15 }}>




                    <div style={{ fontWeight: "bold", textAlign: "center", color: "red" }}>{this.state.errorMessageForAddPackage}</div>

                    <label style={{ paddingLeft: 7, fontSize: 17, fontWeight: 600 }}>Customer Status : </label> <CustomerStatus style={{ color: userMetaData.connectionstatus == "Live" ? "green" : "red" }}>{userMetaData.connectionstatus}</CustomerStatus>
                    <label style={{ marginLeft: 15 }}>Balance : <label style={{ color: "#c71585 ", fontWeight: "bold" }}>{'\u20B9'} {this.state.Balance}</label></label>
                    <Button style={{
                        background: "#FF5722",
                        borderColor: "#FF5722",
                        color: "white"
                    }}
                        size="sm" className="mr-1 ml-1 btn-size pull-right " onClick={() => { this.getLcoDetails() }} ><MdRefresh /> Page  Refresh </Button>
                    <Button style={{
                        background: "#55607f",
                        borderColor: "#55607f",
                        color: "white"
                    }}
                        size="sm" className="mr-1 ml-1 btn-size pull-right " onClick={() => { this.setState({ isShowPasswordDialog: !this.state.isShowPasswordDialog }) }} ><MdVpnKey /> Change Password </Button>

                    <Card className="card-shadow mt-2" style={{
                        backgroundColor: " rgb(247, 243, 243)"
                        // backgroundColor:" #eaeaea"
                    }}>

                        <Card.Body>
                            <CustomerStatusDiv>
                            </CustomerStatusDiv>
                            <Card.Title style={{ display: "flex" }}>
                                <div style={{ flex: "1" }}>
                                    <div className="my-2">
                                        {/* <MdPermIdentity className="details" /> */}
                                        <img src={User} className="svg-50 mr-2" alt="User" />
                                        <label> {userMetaData.name}</label></div>
                                    <div className="my-2">
                                        {/* <MdHome className="details" />  */}
                                        <img src={Maps} className="svg-50 mr-2" alt="home" />

                                        <label style={{ display: "inline" }}>{userMetaData.Address}</label></div>
                                    <div>
                                        {/* <MdFiberPin className="details" /> */}
                                        <img src={CreditCard} className="svg-50 mr-2" alt="home" />
                                        <label>{userMetaData.Customernum}</label></div>

                                    <div className="my-2"><label style={{
                                        color: "#0e378a",
                                        fontWeight: " bold"
                                    }}>Monthly Bill </label><label style={{ color: "#c71585 " }}>{'\u20B9'} {userMetaData.baseprice}</label></div>
                                    {this.isShowStarAndEndDate(userMetaData)}
                                </div>
                                <div>
                                    {/* <MdModeEdit
                                        onClick={() => { this.setState({ isShowCustomerDialog: true, userMetaData: this.state.userMetaData }) }}
                                        style={{ color: "#3F51B5", cursor: "pointer" }} size={25} /> */}
                                    <BtnGroupRight>

                                        {this.whichPayOrExtendBtn(userMetaData)}
                                        {this.whichActivateOrDisconnectBtn(userMetaData)}


                                    </BtnGroupRight>
                                    <label className="title">Due Amount :</label>
                                    <Amt style={{ color: "#c71585 " }}>     {'\u20B9'}{userMetaData.topay} </Amt>
                                </div>
                            </Card.Title>
                            <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                                <STBGroup>
                                    <img src={STB} style={{ width: "55px" }} className="svg-50 mr-2 mt-2" alt="stb" />
                                    {this.showSTBSBtn(userMetaData)}

                                </STBGroup>
                                <STBDetails>
                                    <ActivPackage className="mt-1">
                                        Active Packages
                                    </ActivPackage>

                                    <BtnGroupRight>
                                        {/* <Button
                                            style={{ backgroundColor: "transparent", border: "transparent" }}
                                            size="sm" className="mr-1 ml-1 btn-size"
                                            onClick={() => {
                                                if (this.state.currentPack.length) {

                                                    this.setState({ isShowChannelsListDialog: true, isShowDuplicationDialog: false })
                                                } else {
                                                    this.setState({ isEmptyList: true })
                                                }

                                            }}><MdLiveTv size={20} color="blue" /></Button> */}

                                        <Button style={{ backgroundColor: "#8e44ad", borderColor: "#8e44ad" }} size="sm" className="mr-1 ml-1 btn-size" onClick={() => { this.setState({ isShowPackagesListDialog: true }); }}> Channels list</Button>

                                        <Button variant="success" size="sm" className="mr-1 ml-1 btn-size" onClick={() => {
                                            this.getUnmappedPackageList();
                                        }}><MdAddCircle /> Add</Button>

                                        <Button className=" mr-1 ml-1 btn-size" style={{
                                            backgroundColor: "#e74c3c",
                                            color: "white", borderColor: "#e74c3c"
                                        }}
                                            onClick={() => {
                                                this.showRemovePackage();
                                            }}
                                            size="sm" ><MdRemoveCircle /> Remove </Button>





                                        {/* <Button variant="warning" size="sm" className="mr-1 ml-1 btn-size text-light" onClick={() => { this.onClickRemovePackageButton(this.state.userSTBDetails.SNo) }}><MdRemoveCircle /> Remove</Button> */}
                                    </BtnGroupRight>
                                    {this.renderTheSTB()}

                                </STBDetails>
                            </Card.Body>


                        </Card.Body>
                    </Card>

                    <DialogBoxes
                        Balance={this.state.Balance}
                        isShowExtendDialog={this.state.isShowExtendDialog}
                        hindExtendDialog={this.hindExtendDialog.bind(this)}
                        userMetaData={userMetaData}
                        isShowLowBalanceDialog={this.state.isShowLowBalanceDialog}
                        showLowBalanceDialog={this.showLowBalanceDialog.bind(this)}
                        hindLowBalanceDialog={this.hindLowBalanceDialog.bind(this)}
                        extend={this.extend.bind(this)}
                        isShowSuccessDialog={this.state.isShowSuccessDialog}
                        hindSuccessDialog={this.hindSuccessDialog.bind(this)}
                        isShowErrorDialog={this.state.isShowErrorDialog}
                        hindErrorDialog={this.hindErrorDialog.bind(this)}
                        isShowDisconnectDialog={this.state.isShowDisconnectDialog}
                        hindDisconnectDialog={this.hindDisconnectDialog.bind(this)}
                        disconnectFunc={this.disconnectFunc.bind(this)}
                        isShowLowBalanceDialogForActivate={this.state.isShowLowBalanceDialogForActivate}
                        hindLowBalanceDialogForActivate={this.hindLowBalanceDialogForActivate.bind(this)}
                        isShowActivateDialog={this.state.isShowActivateDialog}
                        hindActivateDialog={this.hindActivateDialog.bind(this)}
                        ActivateAndPayNowFunc={this.ActivateAndPayNowFunc.bind(this)}
                        isShowPayNowDialog={this.state.isShowPayNowDialog}
                        hindPayNowDialog={this.hindPayNowDialog.bind(this)}
                        noOfcompletedData={this.state.noOfcompletedData}
                        totalCount={this.state.totalCount}
                        hindProcessDialog={this.hindProcessDialog.bind(this)}
                        isShowProcessDialog={this.state.isShowProcessDialog}
                        isShowPackagesListDialog={this.state.isShowPackagesListDialog}
                        hindPackagesListDialog={this.hindPackagesListDialog.bind(this)}
                        selectedSTB={this.state.selectedSTB}

                        isShowPasswordDialog={this.state.isShowPasswordDialog}
                        changeUserPassword={this.changeUserPassword.bind(this)}
                        hindPasswordDialog={this.hindPasswordDialog.bind(this)}
                        userPassword={this.state.userPassword}
                        passwordErrorMessage={this.state.passwordErrorMessage}
                        submitPassword={this.submitPassword.bind(this)}
                        showPassword={this.state.showPassword}
                        updateShowPassword={this.updateShowPassword.bind(this)}
                        confirmPassword={this.state.confirmPassword}
                        changeConfirmPassword={this.changeConfirmPassword.bind(this)}
                        oldPassword={this.state.oldPassword}
                        changeOldPassword={this.changeOldPassword.bind(this)}
                        changeShowOldPassword={this.changeShowOldPassword.bind(this)}
                        changeShowConfirmPassword={this.changeShowConfirmPassword.bind(this)}
                        showOldPassword={this.state.showOldPassword}
                        showConfirmPassword={this.state.showConfirmPassword}




                    />
                    <RemovePackageDialog
                        isShowRemovePackageDialog={this.state.isShowRemovePackageDialog}
                        showRemovePackage={this.showRemovePackage.bind(this)}
                        removePackageSearchResult={this.state.removePackageSearchResult}
                        hindRemovePackageDialog={this.hindRemovePackageDialog.bind(this)}
                        removePackageSearch={this.removePackageSearch.bind(this)}
                        toggleChangedInRemovePackages={this.toggleChangedInRemovePackages.bind(this)}
                        removePackageFunc={this.removePackageFunc.bind(this)}
                        removePackageList={this.state.removePackageList}
                        userMetaData={userMetaData}
                        Balance={this.state.Balance}

                    />

                    <AddPackageDialog
                        isShowAddPackageDialog={this.state.isShowAddPackageDialog}
                        hindAddPackageDialog={this.hindAddPackageDialog.bind(this)}
                        addPackageSearchResult={this.state.addPackageSearchResult}
                        toggleChangedInAddPackages={this.toggleChangedInAddPackages.bind(this)}
                        addPackageSearchFunc={this.addPackageSearchFunc.bind(this)}
                        Balance={this.state.Balance}
                        addPackageList={this.state.addPackageList}
                        addPackageFunc={this.addPackageFunc.bind(this)}
                        userMetaData={userMetaData}



                    />

                    <Drawer

                        open={this.state.isProcess}
                        onRequestClose={this.toggle}
                        direction='left'

                    >
                        {/* <div>Hey Im inside a drawer!</div> */}
                        <Card className="processing-div">

                            <Card.Body>
                                <Spinner
                                    size={50}
                                    color="#ffffff"
                                ></Spinner>

                                <div><strong>Still Processing.......</strong></div>

                            </Card.Body>
                            <Card.Footer>
                                {/* <Button variant="danger" className="mr-3" onClick={() => this.backTOCustomerSearch()}>Back</Button> */}
                                <Button varient="info" onClick={() => { this.getLcoDetails() }}>Click to Refresh</Button>
                            </Card.Footer>
                        </Card>

                    </Drawer>




                </div>
            </div>







        );
    }
}