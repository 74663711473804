import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
import {
    MdVpnKey,
    MdVisibility,
    MdVisibilityOff


} from 'react-icons/md';
import OtpInput from 'react-otp-input';

export default class DialogBoxs extends React.Component {


    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red" : "green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindCSVOperationDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            <div>




                <Drawer

                    open={this.props.isShowSuccessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}> Success</div>

                            <Undraw
                                primaryColor="green"
                                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                                name="completed" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindSuccessDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowErrorDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Unable to Process !</div>
                            <div style={{ fontSize: 14, fontWeight: "bold", color: "red", marginTop: 10 }}>{this.props.errorMessage}</div>

                            <Undraw
                                primaryColor="red"
                                style={{ marginTop: 10, display: 'block', width: 500, marginBottom: 10, height: 250, marginLeft: 79 }}
                                name="cancel" />


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindErrorDialog();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.props.isShowPasswordDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <center>

                                <div style={{ fontSize: 18, fontWeight: "bold" }}> {this.props.isForResetOTP ? "Reset Password" : "Password"}</div>
                                <MdVpnKey size={50} color='#808080' style={{ marginTop: 5 }} />
                            </center>



                            <input
                                onChange={e => {

                                    this.props.changeUserPassword(e.target.value)
                                }}
                                className="form-control"
                                id="DomainNamemani"
                                // type="name"
                                placeholder=" Password"
                                value={this.props.userPassword}
                                maxLength={16}
                                minLength={8}
                                type={this.props.showPassword ? "text" : "password"}
                                style={{ width: 350, alignItems: "center", textAlign: "center", marginTop: 10 }}
                            />
                            <center>
                                {this.props.showPassword ? <MdVisibility
                                    onClick={() => {
                                        this.props.updateShowPassword(!this.props.showPassword)
                                    }}

                                    size={20} color="red" /> : <MdVisibilityOff
                                    onClick={() => {
                                        this.props.updateShowPassword(!this.props.showPassword)
                                    }}
                                    size={20} color='#000' />}
                            </center>

                            <center>
                                <label style={{ width: 350, fontSize: 11, color: "#808080" }}>{this.props.isForResetOTP ? "" : "For new users, a password will be created automatically; for existing users, please enter the right password."}</label>
                                <br></br>
                                <label style={{ fontSize: 13, color: "red" }}>{this.props.passwordErrorMessage}</label>
                            </center>
                            <div style={{ width: 350 }}>
                                <label style={{ fontSize: 13, color: "#000", marginTop: 10 }}>Password criteria :</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Minimum : 8 and Maximum : 16</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a lower and upper case latters.</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a minimum of one number.</label><br></br>
                                <label style={{ fontSize: 13, color: "#A9A9A9" }}>. Must include a anyone of the sysmbol !@=%&*</label><br></br>
                            </div>
                            {this.props.isForResetOTP ? null : <label
                                onClick={() => {
                                    this.props.sendOTP()
                                }}
                            >Forgot Password?</label>}

                            <center>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindPasswordDialog();

                                    }}
                                >Cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        if (this.props.isForResetOTP == true) {
                                            this.props.sendDataToFirebase()
                                        } else {
                                            this.props.submitPassword();
                                        }


                                    }}
                                >Submit</Button>

                            </center>

                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowOTPDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <center>

                                <div style={{ fontSize: 18, fontWeight: "bold" }}>OTP</div>
                                <MdVpnKey size={50} color='#808080' style={{ marginTop: 5 }} />
                            </center>

                            <OtpInput
                                value={this.props.otp}
                                onChange={this.props.changeotp}
                                numInputs={6}

                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input
                                    type='number'
                                    style={{ borderWidth: 1, borderColor: "red", width: 50, height: 50 }}
                                    {...props} />}
                                inputStyle={{ borderWidth: 1, borderColor: "#000", width: 50, height: 50 }}
                                shouldAutoFocus={true}
                                inputType='number'
                            />

                            {/* <input
                                onChange={e => {

                                    this.props.changeUserPassword(e.target.value)
                                }}
                                className="form-control"
                                id="DomainName"
                                // type="name"
                                placeholder=" Password"
                                value={this.props.userPassword}
                                maxLength={16}
                                minLength={8}
                                type={this.props.showPassword ? "text" : "password"}
                                style={{ width: 350, alignItems: "center", textAlign: "center", marginTop: 10 }}
                            /> */}


                            <center>
                                <label style={{ width: 350, fontSize: 11, color: "#808080" }}>OTP is sent to register mobile number.</label>
                                <br></br>
                                <label style={{ fontSize: 13, color: "red" }}>{this.props.passwordErrorMessage}</label>
                            </center>
                            <div style={{ width: 350 }}>
                                <label style={{ fontSize: 13, color: "#000", marginTop: 10 }}>Resent OTP in </label>
                                <label
                                    onClick={() => {
                                        this.props.sendOTP();
                                    }}
                                    style={{ fontSize: 15, color: "#0842a0", marginTop: 10,marginLeft:10 }}>{this.props.timer != 0 ? ` ${this.props.timer} sec` : " NOW"}</label>
                                <br></br>

                            </div>

                            <center>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {
                                        // this.props.hindPasswordDialog();

                                        this.props.hindShowOTPDialog();

                                    }}
                                >Cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.verifyData();

                                    }}
                                >Submit</Button>

                            </center>

                        </Card.Body>
                    </Card>

                </Drawer>











            </div>
        );
    }
}