import React from "react";
import Drawer from "react-drag-drawer";
// import './index.css';
import Undraw from "react-undraw";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";
export default class DialogBoxs extends React.Component {
  render() {
    return (
      <div>
        <Drawer
          open={this.props.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div
                style={{
                  textAlign: "center",
                  marginLeft: 15,
                  fontSize: 24,
                  color: "#2c3e50 ",
                  fontWeight: "bold",
                  marginBottom: 5
                }}
              >
                LOG OUT
              </div>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                Do you want{" "}
                <span style={{ fontSize: "20px", color: "rgb(199, 21, 133)" }}>
                  Log out
                </span>{" "}
                ?
              </div>

              <Undraw
                className="undrawimg"
                primaryColor="#EA2027"
                name="monitor"
              />

              <Button
                style={{
                  marginLeft: 20,
                  backgroundColor: "#EA2027",
                  border: "#EA2027"
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindLogOutDialog();
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "#28a745", border: "#28a745" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
      </div>
    );
  }
}
