import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import Undraw from "react-undraw";
import Checkboxs from "muicss/lib/react/checkbox";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";

export default class AddDialogBoxs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddSelectedPackages: false,
      isShowAddPackageWarningDialog: false,
      isShowLowBalanceDialogForAddPackage: false
    };
  }

  getRemainDays(data) {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    var days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    // var end=new Date(data.end);
    // var now=new Date();
    // var remTimeStamp=end.getTime()-now.getTime();
    // var remDay=parseInt(remTimeStamp/(1000*60*60*24))
    // console.log("time",remTimeStamp,remDay);

    return days;
  }

  getAmount(data) {
    var amount = 0.0;
    for (var item of data) {
      if (item.toggleMode) {
        console.log(item);

        amount = parseFloat(item.baseprice) + parseFloat(amount);
      }
    }

    if (this.props.userMetaData.daily) {
      var rem = parseInt(this.props.userMetaData.remaining);
      amount = (amount / 30) * rem;

      var taxAmount = amount * 0.18;

      amount = (amount + taxAmount).toFixed(2);
      return amount;
    } else {
      var remDaysinMonthly = this.getRemainDays(this.props.userMetaData);
      amount = (amount / 30) * remDaysinMonthly;
      var taxAmount = amount * 0.18;
      amount = (amount + taxAmount).toFixed(2);
      return amount;
    }
  }
  isShowOkBtn() {
    var isShow = false;
    for (var item of this.props.addPackageList) {
      if (item.toggleMode) {
        isShow = true;
        break;
      }
    }
    if (isShow) {
      return (
        <Button
          style={{
            backgroundColor: "#28a745",
            borderColor: "#28a745",
            marginRight: 15
          }}
          className="mt-3 pull-right"
          onClick={() => {
            if (this.isLowBalance(this.props.addPackageList)) {
              this.setState({ isShowAddPackageWarningDialog: true });
            } else {
              this.setState({ isShowLowBalanceDialogForAddPackage: true });
            }
          }}
        >
          OK
        </Button>
      );
    } else {
      return null;
    }
  }
  getLength(data) {
    var count = 0;
    for (var item of data) {
      if (item.toggleMode) count += 1;
    }

    return count;
  }

  isLowBalance(data) {
    var amount = 0.0;
    var rem = parseInt(this.props.userMetaData.remaining);
    var bal = parseInt(this.props.Balance);
    debugger;
    for (var item of data) {
      if (item.toggleMode) {
        amount = parseFloat(item.baseprice) + parseFloat(amount);
      }
    }
    if (this.props.userMetaData.daily) {
      amount = (amount / 30) * rem;

      var taxAmount = amount * 0.18;
      amount = parseInt(amount + taxAmount);
      debugger;
      if (bal > amount) {
        return true;
      } else {
        return false;
      }
    } else {
      debugger;
      var remDaysinMonthly = this.getRemainDays(this.props.userMetaData);
      amount = (amount / 30) * remDaysinMonthly;
      debugger;

      var taxAmount = amount * 0.18;
      amount = parseInt(amount + taxAmount);
      debugger;
      if (bal > amount) {
        return true;
      } else {
        return false;
      }

      debugger;
    }
  }

  render() {
    return (
      <div>
        <Drawer
          open={this.props.isShowAddPackageDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div
                style={{
                  fontSize: 24,
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#2c3e50 "
                }}
              >
                Add Packages
              </div>

              <div>
                <input
                  style={{
                    border: "2px solid gray",
                    padding: 10,
                    marginTop: 15,
                    marginBottom: 15,
                    display: "block",
                    textAlign: "center",
                    marginLeft: 71
                  }}
                  placeholder="Search..................."
                  onChange={e => {
                    this.props.addPackageSearchFunc(e.target.value);
                  }}
                ></input>

                <div style={{ marginBottom: 10 }}>
                  <div style={{ display: "inline-block", float: "left" }}>
                    <label
                      style={{
                        fontSize: 18,
                        color: "  #2c2c54",
                        marginTop: 25,
                        fontWeight: "bold"
                      }}
                    >
                      Package List :
                    </label>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: 15 }}>
                    <Button
                      style={{
                        backgroundColor: "#8e44ad ",
                        borderColor: "#8e44ad "
                      }}
                      className="mt-3 pull-right"
                      onClick={() => {
                        this.setState({ isShowAddSelectedPackages: true });
                        this.getRemainDays(this.props.userMetaData);
                      }}
                    >
                      Show Selected Packages
                    </Button>
                  </div>
                </div>

                <div
                  style={{ overflow: "auto", maxHeight: "40vh", width: "25vw" }}
                >
                  {this.props.addPackageSearchResult.map(item => {
                    return (
                      <div
                        style={{
                          border: "1px solid gray ",
                          padding: 15,
                          marginBottom: 10
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            float: "left",
                            width: "85%"
                          }}
                        >
                          <div
                            className="cyan"
                            style={{ fontSize: 18, fontWeight: "bold" }}
                          >
                            {item.name}
                          </div>
                          <div className="rup">
                            {"\u20B9"} {item.baseprice}
                          </div>
                        </div>
                        <div
                          style={{ display: "inline-block", marginLeft: 15 }}
                        >
                          <Checkboxs
                            style={{ fontSize: 20, color: "green" }}
                            color="green"
                            onClick={() => {
                              this.props.toggleChangedInAddPackages(item.id);
                            }}
                            checked={item.toggleMode}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Button
                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.props.hindAddPackageDialog();
                }}
              >
                cancel
              </Button>
              {this.isShowOkBtn()}
            </Card.Body>
          </Card>
        </Drawer>

        <Drawer
          open={this.state.isShowAddSelectedPackages}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "#2c3e50 ",
                  textAlign: "center"
                }}
              >
                Selected Packages
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  fontSize: 18,
                  textAlign: "center",
                  color: "#006266",
                  fontWeight: " bold"
                }}
              >
                Number of Package :
                <label
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    color: "rgb(237, 76, 103)"
                  }}
                >
                  {this.getLength(this.props.addPackageSearchResult)}
                </label>
              </div>

              <div>
                <div style={{ overflow: "auto", maxHeight: "30vh" }}>
                  {this.props.addPackageSearchResult.map(item => {
                    if (item.toggleMode) {
                      return (
                        <div
                          style={{
                            border: "1px solid gray ",
                            padding: 15,
                            marginBottom: 10
                          }}
                        >
                          <div style={{ fontSize: 18, fontWeight: "bold" }}>
                            {item.name}
                          </div>
                          <div style={{ color: "#c71585", fontWeight: "bold" }}>
                            {"\u20B9"} {item.baseprice}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>

              <Button
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowAddSelectedPackages: false });
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        <Drawer
          open={this.state.isShowAddPackageWarningDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "#2c3e50 ",
                  textAlign: "center"
                }}
              >
                Add Package
              </div>
              <div style={{ fontSize: 18 }} className="text-center my-3  cyan">
                Do you want to
                <label
                  style={{
                    margin: "0 6px",
                    color: "rgb(237, 76, 103)",
                    fontWeight: "bold"
                  }}
                >
                  Add
                </label>
                ?
              </div>
              <div style={{ marginTop: 15 }}>
                Require Amount :
                <label className="rup mx-2">
                  {"\u20B9" + this.getAmount(this.props.addPackageList)}
                </label>
                (Inc Tax)
              </div>
              <div style={{ marginTop: 5 }}>
                your Balance :
                <label className="rup ml-2">
                  {"\u20B9" + this.props.Balance}
                </label>
              </div>
              <div style={{ marginTop: 5 }}>
                Remaining Amount :
                <label className="rup ml-2">
                  {"\u20B9" +
                    (this.props.Balance -
                      this.getAmount(this.props.addPackageList))}
                </label>
              </div>
              <Undraw
                primaryColor="green"
                style={{
                  width: 300,
                  height: 200,
                  display: "block",
                  marginTop: 15,
                  marginBottom: 15
                }}
                className="undrawimg"
                name="credit-card-payments"
              />

              <Button
                style={{ backgroundColor: "#EA2027", borderColor: "#EA2027" }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowAddPackageWarningDialog: false });
                }}
              >
                cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowAddPackageWarningDialog: false });
                  this.props.hindAddPackageDialog();
                  this.props.addPackageFunc();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        <Drawer
          open={this.state.isShowLowBalanceDialogForAddPackage}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
                Low Balance !
              </div>
              <div style={{ marginTop: 10 }}>
                Require Amount :
                <label>{this.getAmount(this.props.addPackageList)}</label>
              </div>
              <div style={{ marginTop: 10 }}>
                Your Balance : <label>{this.props.Balance}</label>
              </div>

              <Undraw
                primaryColor="red"
                style={{
                  width: 300,
                  height: 200,
                  display: "block",
                  marginTop: 15,
                  marginBottom: 15
                }}
                className="undrawimg"
                name="credit-card-payments"
              />

              <Button
                style={{
                  backgroundColor: "green",
                  borderColor: "green",
                  marginRight: 15
                }}
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLowBalanceDialogForAddPackage: false });
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
      </div>
    );
  }
}
